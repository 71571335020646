import React from 'react'

import { storageRongve } from '../../firebase'

import ActionButton from '../../components/ActionButtons'

export default function RongveAddClub(props) {
    const handleAddClub = async () => {
        const image = document.getElementById('image').files[0];
        const name = document.getElementById('name').value;

        if (image && name) {
            const logoRef = storageRongve.ref("clubLogos/" + name + ".png");
            await logoRef.put(image).then(() => props.setActive(""));
        }
    }

    return (
        <div>
            <form>
                <h3>Legg til klubb</h3>
                <input type='file' className='form-control mb-2' id='image' />
                <input type='text' className='form-control mb-2' id='name' placeholder='Klubbens navn' />
            </form>
            <ActionButton setActive={props.setActive} onClick={() => handleAddClub()} />
        </div>
    )
}
