import React from 'react'

export default function ButtonBox(props) {
    return (
        <div className='col-4 p-2'>
            <button className='p-4 w-100 h-100 btn btn-primary mb-2' onClick={() => props.setActive(props.item)}>
                <h1>{props.text}</h1>
            </button>
        </div>
    )
}