import React from "react";

import { auth } from "../../firebase";

import ButtonBox from "../../components/ButtonBox";
import RongvePostNews from "./RongvePostNews";
import RongveChangeNews from "./RongveChangeNews";
import RongveDeleteNews from "./RongveDeleteNews";
import RongvePostMemberBenefits from "./RongvePostMemberBenefits";
import RongveChangeMemberBenefits from "./RongveChangeMemberBenefits";
import RongveDeleteMemberBenefits from "./RongveDeleteMemberBenefits";
import RongveAddClub from "./RongveAddClub";
import RongveDeleteClub from "./RongveDeleteClub";
import RongveAddCoach from "./RongveAddCoach";
import RongveDeleteCoach from "./RongveDeleteCoach";
import RongvePushNotifications from "./RongvePushNotifications";
import RongvePostForTrainers from "./RongvePostForTrainers.tsx";
import RongveDeleteForTrainers from "./RongveDeleteForTrainers.tsx";
import RongveChangeForTrainers from "./RongveChangeForTrainers.tsx";

export default function Rongve() {
  const [active, setActive] = React.useState("");

  return (
    <div className="container">
      {active === "" && (
        <>
          <div className="row text-center mb-2">
            <ButtonBox
              text={"Legg ut nyhet"}
              setActive={setActive}
              item={"postNews"}
            />
            <ButtonBox
              text={"Endre nyhet"}
              setActive={setActive}
              item={"changeNews"}
            />
            <ButtonBox
              text={"Slett nyhet"}
              setActive={setActive}
              item={"deleteNews"}
            />
            <ButtonBox
              text={"Legg ut medlemsfordel"}
              setActive={setActive}
              item={"postMemberBenefits"}
            />
            <ButtonBox
              text={"Endre medlemsfordel"}
              setActive={setActive}
              item={"changeMemberBenefits"}
            />
            <ButtonBox
              text={"Slett medlemsfordel"}
              setActive={setActive}
              item={"deleteMemberBenefits"}
            />
            <ButtonBox
                text={"Legg ut nyhet for trenere"}
                setActive={setActive}
                item={"postForTrainers"}
            />
            <ButtonBox
                text={"Endre nyhet for trenere"}
                setActive={setActive}
                item={"changeForTrainers"}
            />
            <ButtonBox
                text={"Slett nyhet for trenere"}
                setActive={setActive}
                item={"deleteForTrainers"}
            />
            <ButtonBox
              text={"Legg til klubb"}
              setActive={setActive}
              item={"addClub"}
            />
            <ButtonBox
              text={"Slett klubb"}
              setActive={setActive}
              item={"deleteClub"}
            />
            <ButtonBox
              text={"Legg til trener"}
              setActive={setActive}
              item={"addCoach"}
            />
            <ButtonBox
              text={"Slett trener"}
              setActive={setActive}
              item={"deleteCoach"}
            />
            <ButtonBox
              text={"Push notifications"}
              setActive={setActive}
              item={"pushNotifications"}
            />
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={() => {
                auth.signOut();
                window.location.href = "/";
              }}
            >
              Logg ut
            </button>
          </div>
        </>
      )}
      {active === "postNews" && <RongvePostNews setActive={setActive} />}
      {active === "changeNews" && <RongveChangeNews setActive={setActive} />}
      {active === "deleteNews" && <RongveDeleteNews setActive={setActive} />}
      {active === "postMemberBenefits" && (
        <RongvePostMemberBenefits setActive={setActive} />
      )}
      {active === "changeMemberBenefits" && (
        <RongveChangeMemberBenefits setActive={setActive} />
      )}
      {active === "deleteMemberBenefits" && (
        <RongveDeleteMemberBenefits setActive={setActive} />
      )}
      {active === "postForTrainers" && <RongvePostForTrainers setActive={setActive} />}
      {active === "changeForTrainers" && <RongveChangeForTrainers setActive={setActive} />}
      {active === "deleteForTrainers" && <RongveDeleteForTrainers setActive={setActive} />}
      {active === "addClub" && <RongveAddClub setActive={setActive} />}
      {active === "deleteClub" && <RongveDeleteClub setActive={setActive} />}
      {active === "addCoach" && <RongveAddCoach setActive={setActive} />}
      {active === "deleteCoach" && <RongveDeleteCoach setActive={setActive} />}
      {active === "pushNotifications" && (
        <RongvePushNotifications setActive={setActive} />
      )}
    </div>
  );
}
