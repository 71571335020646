import React from "react";

import ActionButtons from "../../components/ActionButtons.js";

import { firestoreRongve, storageRongve } from "../../firebase.js";

import Axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function RongvePostForTrainers(props) {
  const [users, setUsers] = React.useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const [push, setPush] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [selectedClubs, setSelectedClubs] = React.useState([]);
  const [clubs, setClubs] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  const getClubInfo = async () => {
    const logoRef = storageRongve.ref("clubLogos/");
    const newClubs = [];
    const res = await logoRef.listAll();
    await Promise.all(
      res.items.map(async (itemRef) => {
        newClubs.push(itemRef.name);
      })
    );

    newClubs.forEach((club, index) => {
      newClubs[index] = club.slice(0, -4);
    });

    setClubs(newClubs);
  };

  React.useEffect(() => {
    getClubInfo();
    getUsers();
  }, []);

  const handleClubSelection = (club) => {
    if (selectedClubs.includes(club)) {
      setSelectedClubs(selectedClubs.filter((id) => id !== club));
    } else {
      setSelectedClubs((prev) => [...prev, club]);
    }
  };

  const postNews = async () => {
    const imageInput = document.getElementById("image") as HTMLInputElement;
    const image = imageInput.files[0];
    let imageBase64 = null;
    if (image) {
      imageBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => resolve(reader.result.slice(22));
        reader.onerror = (error) => reject(error);
      });
    }

    const titleInput = document.getElementById("title") as HTMLInputElement;
    const dateInput = document.getElementById("date") as HTMLInputElement;

    if (titleInput && content && dateInput) {
      const title = titleInput.value;
      const date = dateInput.value;

      firestoreRongve
        .collection("coachNews")
        .add({
          image: imageBase64,
          title: title,
          content: content,
          date: date,
          clubs: selectedClubs,
          trainer: true,
        })
        .then(() => props.setActive(""))
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
      handlePushNotification();
    }
  };

  const getUsers = async () => {
    const usersRef = firestoreRongve.collection("users");
    const snapshot = await usersRef.get();
    const userList = [];
    snapshot.forEach((doc) => {
      const userData = doc.data();
      userList.push({ id: doc.id, ...userData });
    });
    setUsers(userList);
  };

  const handlePushNotification = () => {
    let pushTokens: string[] = [];
    const titleInput = document.getElementById("title") as HTMLInputElement;

    users.forEach((user) => {
      if (user.expoPushToken && user.expoPushToken.length > 0) {
        const userClubIds = (user.clubIds || []).map((id) =>
          id.replace(".png", "")
        );

        const isForEveryone = selectedClubs.includes("Alle") && user.trainer;

        const relevantClubs = userClubIds.filter((club) =>
          selectedClubs.includes(club)
        );
        const hasClub = relevantClubs.length > 0;
        const isOnlyRolesNews =
          selectedClubs.includes(user.userType) &&
          !selectedClubs.some((c) => clubs.includes(c));
        const isGeneralRoleNews =
          selectedClubs.includes(user.userType) && selectedClubs.length === 1;
        const isRoleAndClubNews =
          hasClub && selectedClubs.includes(user.userType);
        const isGeneralClubNews = hasClub && selectedClubs.length === 1;
        const isTrainerNews = user.trainer && hasClub;

        console.log(user.userType, user.trainer);

        if (
          isForEveryone ||
          isGeneralRoleNews ||
          isOnlyRolesNews ||
          isRoleAndClubNews ||
          isGeneralClubNews ||
          isTrainerNews
        ) {
          pushTokens.push(user.expoPushToken);
        }
      }
    });

    let refactoredPushTokens: Set<string> | string = new Set(pushTokens);
    refactoredPushTokens = JSON.stringify(Array.from(refactoredPushTokens));
    console.log(refactoredPushTokens);

    if (push && titleInput) {
      const title = titleInput.value;
      setAlerts([...alerts, { title }]);

      Axios.post(
        `https://webnow-adminpanel.ew.r.appspot.com/send?pushTokens=${refactoredPushTokens}&title=Ny nyhet for trenere&body=${title}`
      ).then((response) => console.log(response.data));
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedClubs([...clubs]);
      setSelectAll(true);
      setSelectedClubs((prev) => [...prev, "Alle"]);
      const checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:not(#selectAll)'
      ) as NodeListOf<HTMLInputElement>;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else {
      setSelectedClubs([]);
      setSelectAll(false);
    }
  };

  return (
    <div className="mb-2 p-2">
      <h3>Legg ut nyhet for trenere</h3>
      <form>
        <input type="file" className="form-control mb-2" id="image" />
        <input
          type="text"
          className="form-control mb-2"
          id="title"
          placeholder="Tittel"
        />
        <ReactQuill
          value={content}
          onChange={setContent}
          className="bg-white rounded text-black mb-2"
          placeholder="Innhold"
          id="content"
          style={{ borderRadius: 10, overflow: "hidden" }}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          }}
        />
        <input type="date" className="form-control mb-2" id="date" />
        <div className="form-check form-check-inline mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAll"
            checked={selectAll}
            onChange={() => handleSelectAll()}
          />
          <label className="form-check-label" htmlFor="selectAll">
            Alle
          </label>
        </div>
        <h4>Klubb(er):</h4>
        {clubs.map((club) => (
          <div className="form-check form-check-inline mb-2" key={club}>
            <input
              className="form-check-input"
              type="checkbox"
              id={club}
              value={club}
              disabled={selectAll}
              onChange={() => handleClubSelection(club)}
            />
            <label className="form-check-label" htmlFor={club}>
              {club}
            </label>
          </div>
        ))}
        <div className="form-check form-check-inline mb-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="push"
            value="Push notifikasjon"
            onChange={(e) => setPush(!!e.target.value)}
          />
          <label className="form-check-label" htmlFor="push">
            Send notifikasjon?
          </label>
        </div>
        <ActionButtons setActive={props.setActive} onClick={() => postNews()} />
      </form>
    </div>
  );
}
