import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

// WEBNOWADMIN
const firebaseConfig = {
    apiKey: "AIzaSyCHG1SntbJojiAMfNe5S9sqvH0giVilkFw",
    authDomain: "webnow-adminpanel.firebaseapp.com",
    projectId: "webnow-adminpanel",
    storageBucket: "webnow-adminpanel.appspot.com",
    messagingSenderId: "308239920358",
    appId: "1:308239920358:web:fce0e354ac253d23ab5f27",
    measurementId: "G-KV2M0P4GB0"
};
const firebaseInstance = firebase.initializeApp(firebaseConfig);
const auth = firebaseInstance.auth();
const firestore = firebaseInstance.firestore();

// RONGVE
const firebaseConfigRongve = {
    apiKey: "AIzaSyBI0uUHpduGPQ0wEl51Xk2eeTYujbKTJ64",
    authDomain: "fir-rongve-app.firebaseapp.com",
    databaseURL: "https://firebase-rongve-app-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "firebase-rongve-app",
    storageBucket: "firebase-rongve-app.appspot.com",
    messagingSenderId: "992558358745",
    appId: "1:992558358745:web:7b99835ee32b1ba7efdec8"
};
const firebaseRongveInstance = firebase.initializeApp(firebaseConfigRongve, "firebaseConfigRongve");
const authRongve = firebaseRongveInstance.auth();
const firestoreRongve = firebaseRongveInstance.firestore();
const storageRongve = firebaseRongveInstance.storage();

export { auth, firestore, authRongve, firestoreRongve, storageRongve };