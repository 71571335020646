import React from 'react'

export default function ActionButton(props) {
    return (
        <div className='row'>
            <div className='mb-2 col-6'>
                <button className='btn btn-primary w-100' onClick={() => props.setActive("")}>&larr; Tilbake</button>
            </div>
            <div className='mb-2 col-6'>
                <button type='button' className='btn btn-primary w-100' onClick={props.onClick}>Kjør</button>
            </div>
        </div>
    )
}
