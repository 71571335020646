import React from 'react'

import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const handleLogin = (email, pass) => {
  auth.signInWithEmailAndPassword(email, pass)
    .then(() => window.location.href = '/adminpanel')
}

export default function Login() {
  const [user, loading] = useAuthState(auth);

  if (user && !loading) window.location.href = '/adminpanel';

  return (
    <div className='bg-dark text-white d-flex justify-content-center align-items-center vh-100 flex-column'>
      <img src="http://webnow.no/assets/img/WebnowTextLogoWhite.png" alt="Webnow logo" className='mb-4' />
      <form className='d-flex flex-column'>
        <input type='email' placeholder='E-post' className='form-control mb-2' />
        <input type='password' placeholder='Passord' className='form-control mb-2' />
        <button type='button' className='btn btn-primary' onClick={() => {
          const email = document.querySelector('input[type="email"]').value
          const pass = document.querySelector('input[type="password"]').value
          handleLogin(email, pass)
        }}>Logg inn</button>
      </form>
    </div>
  )
}
