import React, { useEffect, useState } from "react";
import Axios from "axios";
import ActionButtons from "../../components/ActionButtons";

import { storageRongve, firestoreRongve } from "../../firebase";

const otherCategories = ["Trener", "Forelder", "Spiller"];

export default function RongvePushNotifications(props) {
  const [users, setUsers] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const getUsers = async () => {
    const usersRef = firestoreRongve.collection("users");
    const snapshot = await usersRef.get();
    const userList = [];
    snapshot.forEach((doc) => {
      const userData = doc.data();
      userList.push({ id: doc.id, ...userData });
    });
    setUsers(userList);
  };

  const getClubInfo = async () => {
    const logoRef = storageRongve.ref("clubLogos/");
    const newClubs = [];
    const res = await logoRef.listAll();
    await Promise.all(
      res.items.map(async (itemRef) => {
        newClubs.push(itemRef.name);
      })
    );

    newClubs.forEach((club, index) => {
      newClubs[index] = club.slice(0, -4);
    });

    setClubs(newClubs);
  };

  const handleClubSelection = (club) => {
    if (selectedClubs.includes(club)) {
      setSelectedClubs(selectedClubs.filter((id) => id !== club));
    } else {
      setSelectedClubs([...selectedClubs, club]);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedClubs([...clubs, ...otherCategories]);
      setSelectAll(true);
      setSelectedClubs((prev) => [...prev, "Alle"]);
      const checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:not(#selectAll)'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else {
      setSelectedClubs([]);
      setSelectAll(false);
    }
  };

  const handlePushNotification = () => {
    let pushTokens = [];
    const title = document.getElementById("title").value;
    const body = document.getElementById("body").value;

    users.forEach((user) => {
      if (user.expoPushToken && user.expoPushToken.length > 0) {
        const userClubIds = (user.clubIds || []).map((id) =>
          id.replace(".png", "")
        );

        const isForEveryone = selectedClubs.includes("Alle");

        const relevantClubs = userClubIds.filter((club) =>
          selectedClubs.includes(club)
        );
        const hasClub = relevantClubs.length > 0;
        const isOnlyRolesNews =
          selectedClubs.includes(user.userType) &&
          !selectedClubs.some((c) => clubs.includes(c));
        const isGeneralRoleNews =
          selectedClubs.includes(user.userType) && selectedClubs.length === 1;
        const isRoleAndClubNews =
          hasClub && selectedClubs.includes(user.userType);
        const isGeneralClubNews = hasClub && selectedClubs.length === 1;
        const isTrainerNews =
          user.trainer &&
          selectedClubs.includes("Trener") &&
          (hasClub || selectedClubs.length === 1);

        console.log(!selectedClubs.some((c) => clubs.includes(c)));

        if (
          isForEveryone ||
          isGeneralRoleNews ||
          isRoleAndClubNews ||
          isOnlyRolesNews ||
          isGeneralClubNews ||
          isTrainerNews
        ) {
          pushTokens.push(user.expoPushToken);
        }
      }
    });

    pushTokens = new Set(pushTokens);
    pushTokens = JSON.stringify(Array.from(pushTokens));

    if (title && body && selectedClubs.length > 0) {
      setAlerts([...alerts, { title }]);

      Axios.post(
        `https://webnow-adminpanel.ew.r.appspot.com/send?pushTokens=${pushTokens}&title=${title}&body=${body}`
      ).then((response) => console.log(response.data));
    }
  };

  useEffect(() => {
    getUsers();
    getClubInfo();
  }, []);

  return (
    <div className="mb-2 p-2">
      <h3>Send push notifikasjon</h3>
      <input
        type="text"
        className="form-control mb-2"
        id="title"
        placeholder="Tittel"
      />
      <input
        type="text"
        className="form-control mb-2"
        id="body"
        placeholder="Innhold"
      />
      <div className="form-check form-check-inline mb-2">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectAll"
          value={selectAll}
          onChange={() => handleSelectAll()}
        />
        <label className="form-check-label" htmlFor="selectAll">
          Alle
        </label>
      </div>
      <h4>Klubb(er):</h4>
      {clubs.map((club) => (
        <div className="form-check form-check-inline mb-2" key={club}>
          <input
            className="form-check-input"
            type="checkbox"
            id={club}
            value={club}
            disabled={selectAll}
            onChange={() => handleClubSelection(club)}
          />
          <label className="form-check-label" htmlFor={club}>
            {club}
          </label>
        </div>
      ))}
      <h4>Annet:</h4>
      {otherCategories.map((category) => (
        <div className="form-check form-check-inline mb-2" key={category}>
          <input
            className="form-check-input"
            type="checkbox"
            id={category}
            value={category}
            disabled={selectAll}
            onChange={() => handleClubSelection(category)}
          />
          <label className="form-check-label" htmlFor={category}>
            {category}
          </label>
        </div>
      ))}
      <ActionButtons
        setActive={props.setActive}
        onClick={handlePushNotification}
      />
      {alerts.length > 0 &&
        alerts.map((alert) => {
          return (
            <div className="alert alert-success mb-2" role="alert">
              Push notifikasjon med tittel <b>{alert.title}</b> sendt ut. Kan ta
              opptil 20 minutter før den vises.
            </div>
          );
        })}
    </div>
  );
}
