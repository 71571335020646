import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css';

import Login from './pages/Login'
import AdminPanel from './pages/AdminPanel'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/adminpanel' element={<AdminPanel />} />
      </Routes>
    </BrowserRouter>
  );
}