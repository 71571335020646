import React from 'react'

import ActionButtons from '../../components/ActionButtons'

import { storageRongve, firestoreRongve } from '../../firebase'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import BackButton from '../../components/BackButton'

const otherCategories = [
    "Trenere",
    "Foreldre",
    "Spillere"
]

export default function RongveEditNews(props) {
    const [selectedClubs, setSelectedClubs] = React.useState([]);
    const [clubs, setClubs] = React.useState([]);
    const [content, setContent] = React.useState('');
    const [newsPosts, setNewsPosts] = React.useState([]);
    const [selectedPostId, setSelectedPostId] = React.useState(null);

    const getClubInfo = async () => {
        const logoRef = storageRongve.ref("clubLogos/");
        const newClubs = [];
        const res = await logoRef.listAll();
        await Promise.all(res.items.map(async (itemRef) => {
            newClubs.push(itemRef.name);
        }));

        newClubs.forEach((club, index) => {
            newClubs[index] = club.slice(0, -4);
        });

        setClubs(newClubs);
    };

    const getNewsPost = async (postId) => {
        const postRef = firestoreRongve.collection("news").doc(postId);
        const doc = await postRef.get();
        if (doc.exists) {
            const data = doc.data();
            setContent(data.content);
            document.getElementById('title').value = data.title;
            document.getElementById('date').value = data.date;
            setSelectedClubs(data.clubs);
        } else {
            console.log("No such document!");
        }
    }

    const fetchNewsPosts = async () => {
        const newsRef = firestoreRongve.collection("news");
        const snapshot = await newsRef.get();
        const posts = [];
        snapshot.forEach(doc => {
            posts.push({ id: doc.id, ...doc.data() });
        });
        setNewsPosts(posts);
    }

    const deleteImage = async () => {
        if (selectedPostId) {
            const postRef = firestoreRongve.collection("news").doc(selectedPostId);
            await postRef.update({ image: "" });
            document.getElementById('image').value = "";
        }
    }

    React.useEffect(() => {
        getClubInfo();
        fetchNewsPosts();
    }, [])

    const loadNewsPost = async (postId) => {
        setSelectedPostId(postId);
        await getNewsPost(postId);
    }

    const renderNewsList = () => (
        <div>
            <h3>Vennligst velg hvilken nyhet du vil endre</h3>
            {newsPosts.map(post => (
                <div key={post.id} className='mb-2'>
                    <button className='btn btn-primary me-2 d-inline' onClick={() => loadNewsPost(post.id)}>Endre</button>
                    <p className='d-inline'>{post.title}</p>
                </div>
            ))}
            <BackButton setActive={props.setActive} />
        </div>
    );

    const updateNews = async () => {
        const title = document.getElementById('title').value;
        const date = document.getElementById('date').value;
        const image = document.getElementById('image').files[0];

        if (title && content && date) {
            const updatedData = {
                title: title,
                content: content,
                date: date,
                clubs: selectedClubs
            };

            if (image) {
                const imageBase64 = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onload = () => resolve(reader.result.slice(22));
                    reader.onerror = error => reject(error);
                });
                updatedData.image = imageBase64;
            }

            firestoreRongve.collection("news").doc(selectedPostId).update(updatedData)
                .then(() => props.setActive(""))
                .catch((error) => {
                    console.error("Error updating document: ", error);
                });
        }
    }

    const handleClubSelection = (club) => {
        if (selectedClubs.includes(club)) {
            setSelectedClubs(selectedClubs.filter(id => id !== club));
        } else {
            setSelectedClubs([...selectedClubs, club]);
        }
    }

    return (
        <div className='mb-2 p-2'>
            {selectedPostId ? (
                <>
                    <h3>Rediger nyhet</h3>
                    <form>
                        <input type='file' className='form-control mb-2' id='image' />
                        <button type="button" className='btn btn-primary mb-2' onClick={deleteImage}>Slett bilde</button>
                        <input type='text' className='form-control mb-2' id='title' placeholder='Tittel' />
                        <ReactQuill
                            value={content}
                            onChange={setContent}
                            className='bg-white rounded text-black mb-2'
                            placeholder='Innhold'
                            id='content'
                            style={{ borderRadius: 10, overflow: 'hidden' }}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, 3, false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link'],
                                    ['clean']
                                ]
                            }}
                        />
                        <input type='date' className='form-control mb-2' id='date' />
                        <h4>Klubb(er):</h4>
                        {clubs.map((club) => (
                            <div className='form-check form-check-inline mb-2' key={club}>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id={club}
                                    value={club}
                                    checked={selectedClubs.includes(club)}
                                    onChange={() => handleClubSelection(club)}
                                />
                                <label className='form-check-label' htmlFor={club}>{club}</label>
                            </div>
                        ))}
                        <h4>Annet:</h4>
                        {otherCategories.map((category) => (
                            <div className='form-check form-check-inline mb-2' key={category}>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id={category}
                                    value={category}
                                    checked={selectedClubs.includes(category)}
                                    onChange={() => handleClubSelection(category)}
                                />
                                <label className='form-check-label' htmlFor={category}>{category}</label>
                            </div>
                        ))}
                        <ActionButtons setActive={props.setActive} onClick={() => updateNews()} />
                    </form>
                </>
            ) : (
                renderNewsList()
            )}
        </div>
    )
}