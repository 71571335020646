import React from 'react';

import { firestoreRongve } from '../../firebase';
import BackButton from '../../components/BackButton';

export default function RongveDeleteMemberBenefits(props) {
    const [memberBenefits, setMemberBenefits] = React.useState([]);

    const fetchMemberBenefits = async () => {
        const benefitsRef = firestoreRongve.collection("memberBenefits");
        const snapshot = await benefitsRef.get();
        const benefits = [];
        snapshot.forEach(doc => {
            benefits.push({ id: doc.id, ...doc.data() });
        });
        setMemberBenefits(benefits);
    }

    const deleteMemberBenefit = async (benefitId) => {
        await firestoreRongve.collection("memberBenefits").doc(benefitId).delete();
        fetchMemberBenefits();
    }

    React.useEffect(() => {
        fetchMemberBenefits();
    }, [])

    return (
        <div className='mb-2 p-2'>
            <h3>Vennligst velg hvilken medlemsfordel du vil slette</h3>
            {memberBenefits.map(benefit => (
                <div key={benefit.id} className='mb-2'>
                    <button className='btn btn-danger me-2 d-inline' onClick={() => deleteMemberBenefit(benefit.id)}>Slett</button>
                    <p className='d-inline'>{benefit.title}</p>
                </div>
            ))}
            <BackButton setActive={props.setActive} />
        </div>
    )
}