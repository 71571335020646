import React, { useState, useEffect } from 'react';

import ActionButtons from '../../components/ActionButtons';

import { firestoreRongve } from '../../firebase';

export default function RongveAddCoach(props) {
    const [users, setUsers] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const fetchUsers = async () => {
        const usersRef = firestoreRongve.collection("users");
        const snapshot = await usersRef.get();
        const userList = [];
        snapshot.forEach(doc => {
            const userData = doc.data();
            if (userData.trainer === undefined || userData.trainer === false) {
                userList.push({ id: doc.id, ...userData });
            }
        });
        setUsers(userList);
    };

    const makeTrainer = async (userId) => {
        await firestoreRongve.collection("users").doc(userId).update({ trainer: true });
        fetchUsers();
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const filteredUsers = users.filter(user => user.name.toLowerCase().includes(searchInput.toLowerCase()));

    return (
        <div>
            <h3>Søk og velg en bruker for å gjøre dem til trener</h3>
            <input
                type="text"
                className="form-control mb-2"
                placeholder="Søk bruker"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            {filteredUsers.map(user => (
                <div key={user.id} className="mb-2">
                    <button className="btn btn-primary me-2 d-inline" onClick={() => makeTrainer(user.id)}>Gjør til trener</button>
                    <p className="d-inline">{user.name}</p>
                </div>
            ))}
            <ActionButtons setActive={props.setActive} />
        </div>
    );
}