import React from 'react'

import { auth, firestore } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

import Rongve from '../companies/Rongve/Rongve'

export default function AdminPanel() {
    const [userData, setUserData] = React.useState([])
    const [user, loading] = useAuthState(auth);

    React.useEffect(() => {
        if (user) {
            const userRef = firestore.collection('users').where('email', '==', user.email)
            userRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setUserData(doc.data())
                })
            })
        }
    }, [user])

    if (!user && !loading) window.location.href = '/';

    return (
        <div className='bg-dark text-white py-5'>
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <img src="http://webnow.no/assets/img/WebnowTextLogoWhite.png" alt="Webnow logo" className='mb-4' />
                <h1 className='mb-2'>Adminpanel ({userData.company})</h1>
            </div>
            {userData.company === 'Rongve' && <Rongve />}
        </div>
    )
}
