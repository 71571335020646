import React from 'react';

import { firestoreRongve } from '../../firebase';
import BackButton from '../../components/BackButton';

export default function RongveDeleteNews(props) {
    const [newsPosts, setNewsPosts] = React.useState([]);

    const fetchNewsPosts = async () => {
        const newsRef = firestoreRongve.collection("news");
        const snapshot = await newsRef.get();
        const posts = [];
        snapshot.forEach(doc => {
            posts.push({ id: doc.id, ...doc.data() });
        });
        setNewsPosts(posts);
    }

    const deleteNews = async (postId) => {
        await firestoreRongve.collection("news").doc(postId).delete();
        fetchNewsPosts();
    }

    React.useEffect(() => {
        fetchNewsPosts();
    }, [])

    return (
        <div className='mb-2 p-2'>
            <h3>Vennligst velg hvilken nyhet du vil slette</h3>
            {newsPosts.map(post => (
                <div key={post.id} className='mb-2'>
                    <button className='btn btn-danger me-2 d-inline' onClick={() => deleteNews(post.id)}>Slett</button>
                    <p className='d-inline'>{post.title}</p>
                </div>
            ))}
            <BackButton setActive={props.setActive} />
        </div>
    )
}