import React from 'react';

import { storageRongve } from '../../firebase';
import BackButton from '../../components/BackButton';

export default function RongveDeleteClub(props) {
    const [clubs, setClubs] = React.useState([]);

    const fetchClubs = async () => {
        const logoRef = storageRongve.ref("clubLogos/");
        const newClubs = [];
        const res = await logoRef.listAll();
        await Promise.all(res.items.map(async (itemRef) => {
            newClubs.push(itemRef.name.slice(0, -4));
        }));
        setClubs(newClubs);
    };

    const deleteClubLogo = async (clubName) => {
        const logoRef = storageRongve.ref("clubLogos/" + clubName + ".png");
        await logoRef.delete();
        fetchClubs();
    };

    React.useEffect(() => {
        fetchClubs();
    }, []);

    return (
        <div className='mb-2 p-2'>
            <h3>Vennligst velg hvilken klubblogo du vil slette</h3>
            {clubs.map(club => (
                <div key={club} className='mb-2'>
                    <button className='btn btn-danger me-2 d-inline' onClick={() => deleteClubLogo(club)}>Slett</button>
                    <p className='d-inline'>{club}</p>
                </div>
            ))}
            <BackButton setActive={props.setActive} />
        </div>
    );
}